import { format, parse } from 'date-fns';
import _ from 'lodash';
import LZString from 'lz-string';
import { formatQuery } from 'react-querybuilder';

const queryCompressionEnabled = true;

const initialQuery = Object.freeze({
  rules: Object.freeze([]),
  combinator: 'and',
  not: false,
});

const operators = Object.freeze({
  equal: Object.freeze({ name: '=', label: '=' }),
  notEqual: Object.freeze({ name: '!=', label: '!=' }),
  lessThan: Object.freeze({ name: '<', label: '<' }),
  lessThanOrEqual: Object.freeze({ name: '<=', label: '<=' }),
  greaterThan: Object.freeze({ name: '>', label: '>' }),
  greaterThanOrEqual: Object.freeze({ name: '>=', label: '>=' }),
  isNull: Object.freeze({ name: 'null', label: 'is null' }),
  isNotNull: Object.freeze({ name: 'notNull', label: 'is not null' }),
  contains: Object.freeze({ name: 'contains', label: 'contains' }),
  doesNotContain: Object.freeze({
    name: 'doesNotContain',
    label: 'does not contain',
  }),
  in: Object.freeze({ name: 'in', label: 'in' }),
  notIn: Object.freeze({ name: 'notIn', label: 'not in' }),
  between: Object.freeze({ name: 'between', label: 'between' }),
  notBetween: Object.freeze({ name: 'notBetween', label: 'not between' }),
  beginsWith: Object.freeze({ name: 'beginsWith', label: 'begins with' }),
  doesNotBeginWith: Object.freeze({
    name: 'doesNotBeginWith',
    label: 'does not begins with',
  }),
  endsWith: Object.freeze({ name: 'endsWith', label: 'ends with' }),
  doesNotEndWith: Object.freeze({
    name: 'doesNotEndWith',
    label: 'does not end with',
  }),
});
const operatorsList = Object.freeze(_.values(operators));

const inputTypes = Object.freeze({
  Text: 'text',
  Number: 'number',
  Date: 'text',
  Boolean: 'boolean',
  Email: 'text',
  Phone: 'text',
  Decimal: 'number',
  Locale: 'text',
});

const dataTypes = Object.freeze({
  Text: 'text',
  Number: 'number',
  Date: 'date',
  Boolean: 'boolean',
  Email: 'text',
  Phone: 'text',
  Decimal: 'number',
  Locale: 'text',
});

const dataExtensionOperators = Object.freeze({
  equalityOperators: [
    operators.equal,
    operators.notEqual,
    operators.isNull,
    operators.isNotNull,
  ],

  comparisonOperators: [
    operators.lessThan,
    operators.lessThanOrEqual,
    operators.greaterThan,
    operators.greaterThanOrEqual,
  ],

  collectionOperators: [
    operators.between,
    operators.notBetween,
    operators.in,
    operators.notIn,
  ],

  literalOperators: [
    operators.beginsWith,
    operators.doesNotBeginWith,
    operators.contains,
    operators.doesNotContain,
    operators.endsWith,
    operators.doesNotEndWith,
  ],
});

const subscriberOperators = Object.freeze({
  equalityOperators: [
    operators.equal,
    operators.notEqual,
    operators.isNull,
    operators.isNotNull,
  ],

  comparisonOperators: [
    operators.lessThan,
    operators.lessThanOrEqual,
    operators.greaterThan,
    operators.greaterThanOrEqual,
  ],

  collectionOperators: [operators.between, operators.in],

  literalOperators: [operators.contains],
});

const dataExtensionOperatorsByDataTypes = Object.freeze({
  boolean: dataExtensionOperators.equalityOperators,
  date: Object.freeze([
    ...dataExtensionOperators.equalityOperators,
    ...dataExtensionOperators.comparisonOperators,
    ...dataExtensionOperators.collectionOperators,
  ]),
  number: Object.freeze([
    ...dataExtensionOperators.equalityOperators,
    ...dataExtensionOperators.comparisonOperators,
    ...dataExtensionOperators.collectionOperators,
  ]),
  text: Object.freeze([
    ...dataExtensionOperators.equalityOperators,
    ...dataExtensionOperators.comparisonOperators,
    ...dataExtensionOperators.collectionOperators,
    ...dataExtensionOperators.literalOperators,
  ]),
});

const subscriberOperatorsByDataTypes = Object.freeze({
  boolean: subscriberOperators.equalityOperators,
  date: Object.freeze([
    ...subscriberOperators.equalityOperators,
    ...subscriberOperators.comparisonOperators,
    ...subscriberOperators.collectionOperators,
  ]),
  number: Object.freeze([
    ...subscriberOperators.equalityOperators,
    ...subscriberOperators.comparisonOperators,
    ...subscriberOperators.collectionOperators,
  ]),
  text: Object.freeze([
    ...subscriberOperators.equalityOperators,
    ...subscriberOperators.comparisonOperators,
    ...subscriberOperators.collectionOperators,
    ...subscriberOperators.literalOperators,
  ]),
});

const formatTipByDataTypes = Object.freeze({
  boolean: 'true | false',
  date: 'yyyy-MM-dd[ HH:mm[:ss]]',
  number: 'integer | decimal',
  text: 'text',
});

function parseRawQueryParam(rawQueryParam) {
  try {
    return queryCompressionEnabled
      ? JSON.parse(LZString.decompressFromBase64(rawQueryParam)) || initialQuery
      : JSON.parse(rawQueryParam) || initialQuery;
  } catch {
    return initialQuery;
  }
}

function createQueryParam(queryObj) {
  const queryParam = queryObj ? formatQuery(queryObj, 'json_without_ids') : '';
  // const queryParam = queryObj ? formatQuery(queryObj, 'json') : '';
  return queryCompressionEnabled
    ? LZString.compressToBase64(queryParam)
    : queryParam;
}

// raw regex wiith named groups produce unexected results in production. Groups are not included in RegExp.source
const dateRegexes = [
  '(?<yyyy>\\d{4})[-](?<MM>(0[0-9]|1[0-2]))[-](?<dd>([0-2][0-9]|3[01]))',
  '(?<yyyy>\\d{4})[/](?<MM>(0[0-9]|1[0-2]))[/](?<dd>([0-2][0-9]|3[01]))',
  '(?<yyyy>\\d{4})(?<MM>(0[0-9]|1[0-2]))(?<dd>([0-2][0-9]|3[01]))',
  '(?<MM_alt>((|0)[0-9]|1[0-2]))[-](?<dd>((|[0-2])[0-9]|3[01]))[-](?<yyyy>\\d{4})',
  '(?<MM_alt>((|0)[0-9]|1[0-2]))[/](?<dd>((|[0-2])[0-9]|3[01]))[/](?<yyyy>\\d{4})',
  '(?<MM_alt>((|0)[0-9]|1[0-2]))(?<dd>((|[0-2])[0-9]|3[01]))(?<yyyy>\\d{4})',
];
const timeRegex =
  '(?<HH>((|[01])[0-9]|2[0-3])):(?<mm>([0-5][0-9]))(|:(?<ss>[0-5][0-9]))(|\\s+(?<AMPM>([aApP][mM])))';

const dateTimeRegexes = dateRegexes.map((dateRegex) => {
  const r = new RegExp(`^\\s*${dateRegex}(|[\\s|T]${timeRegex})\\s*$`);
  return r;
});

function validateDateValue(dateValue) {
  const originalValue = dateValue;

  try {
    let groups;
    for (const regex of dateTimeRegexes) {
      groups = (dateValue?.match(regex) || {}).groups;
      if (groups?.yyyy && (groups?.MM || groups?.MM_alt) && groups?.dd) {
        break;
      }
    }

    if (groups?.yyyy && (groups?.MM || groups?.MM_alt) && groups?.dd) {
      dateValue = dateValue
        .replace(/[/]/g, '-')
        .replace(/T|\s{2,}/g, ' ')
        .replace(/^\s+|s\+$/g, '');

      let datePattern = groups.MM_alt
        ? dateValue.indexOf('-') >= 0
          ? 'MM-dd-yyyy'
          : 'MMddyyyy'
        : dateValue.indexOf('-') >= 0
        ? 'yyyy-MM-dd'
        : 'yyyyMMdd';

      const h = groups.AMPM ? 'hh' : 'HH';
      const a = groups.AMPM ? ' aa' : '';
      datePattern = groups.ss
        ? `${datePattern} ${h}:mm:ss${a}`
        : groups.HH
        ? `${datePattern} ${h}:mm${a}`
        : `${datePattern}`;

      const date = parse(dateValue, datePattern, new Date());
      const isValid = _.isDate(date);
      return {
        isValid,
        value: isValid ? format(date, 'yyyy-MM-dd HH:mm:ss') : originalValue,
        date,
      };
    }
    return { isValid: false, value: originalValue };
  } catch (e) {
    console.error(e);
    return { isValid: false, value: originalValue };
  }
}

const fieldValidators = {
  number: function (rule, fieldData) {
    const number = Number(rule.value);
    if (rule.value === '' || Number.isNaN(number)) {
      return { isValid: false, value: rule.value };
    }
    return { isValid: true, value: number };
  },
  date: function (rule, fieldData) {
    const dateValidation = validateDateValue(rule.value);
    return {
      isValid: dateValidation.isValid,
      value: dateValidation.value,
      date: dateValidation.date,
    };
  },
  text: function (rule, fieldData) {
    return {
      isValid: rule.value ? true : false,
      value: rule.value,
    };
  },
  boolean: function (rule, fieldData) {
    const boolean =
      rule.value === true
        ? true
        : rule.value === false
        ? false
        : /^true$/i.test(rule.value)
        ? true
        : /^false$/i.test(rule.value)
        ? false
        : null;
    if (boolean === null) {
      return { isValid: false, value: rule.value };
    }
    return { isValid: true, value: boolean };
  },
};

function validateBetween(rule, fieldData) {
  const { from, to } =
    rule.value?.match(/(?<from>[^,]+)(,(?<to>.+)){0,1}/)?.groups || {};
  const fromRule = _.clone(rule);
  fromRule.value = from;
  const toRule = _.merge(_.clone(rule), { value: to });
  toRule.value = to ? to : rule.value?.indexOf(',') > 0 ? '' : undefined;

  const fromValidation = fieldValidators[fieldData.dataType]?.(
    fromRule,
    fieldData
  );
  if (!fromValidation) {
    return { isValid: true, processedValue: `'${from}' and '${to}'` };
  }

  if (fromValidation?.isValid) {
    const toValidation = fieldValidators[fieldData.dataType]?.(
      toRule,
      fieldData
    );
    if (toValidation?.isValid) {
      if (fromValidation.date || toValidation.date) {
        // if (fromValidation.date <= toValidation.date) {
        return {
          isValid: true,
          processedValue: `'${fromValidation.value}' and '${toValidation.value}'`,
        };
        // }
        // return { isValid: false };
      } else {
        //  if (
        //   fromValidation.value.toLowerCase() <= toValidation.value.toLowerCase()
        // ) {
        return {
          isValid: true,
          processedValue: `'${fromValidation.value}' and '${toValidation.value}'`,
        };
      }
      // else {
      //   return { isValid: false };
      // }
    }
  }
  return { isValid: false };
}

function validateIn(rule, fieldData) {
  const parts =
    rule.value?.indexOf(',') >= 0
      ? rule.value?.split(',')
      : rule.value
      ? [rule.value]
      : [];
  // ?.map((part) => _.merge(_.clone(rule), { value: part || '' }));

  const partsResult = [];
  for (const part of parts) {
    const partRule = _.merge(_.clone(rule), { value: part || '' });
    const validation = fieldValidators[fieldData.dataType]?.(
      partRule,
      fieldData
    );
    if (validation?.isValid === false) {
      return { isValid: false };
    }
    partsResult.push(validation ? validation.value : part);
  }

  return {
    isValid: partsResult.length > 0,
    processedValue: `('${partsResult.join("', '")}')`,
  };
}

function validateField(rule, fieldData) {
  if (fieldData) {
    if (/^(null|notNull)$/i.test(rule.operator)) {
      return { isValid: true };
    }

    switch (rule.operator) {
      case QueryUtils.operators.between.name:
      case QueryUtils.operators.notBetween.name:
        return validateBetween(rule, fieldData);

      case QueryUtils.operators.in.name:
      case QueryUtils.operators.notIn.name:
        return validateIn(rule, fieldData);

      default:
        break;
    }

    const validation = fieldValidators[fieldData.dataType]?.(rule, fieldData);

    if (validation?.isValid === false) {
      return { isValid: false };
    }

    let processedValue = validation ? validation.value : rule.value;
    let isValid = validation?.isValid ?? true;
    if (isValid) {
      switch (rule.operator) {
        case QueryUtils.operators.contains.name:
        case QueryUtils.operators.doesNotContain.name:
          processedValue = `'%${processedValue}%'`;
          break;

        case QueryUtils.operators.beginsWith.name:
        case QueryUtils.operators.doesNotBeginWith.name:
          processedValue = `'${processedValue}%'`;
          break;

        case QueryUtils.operators.endsWith.name:
        case QueryUtils.operators.doesNotEndWith.name:
          processedValue = `'%${processedValue}'`;
          break;

        default:
          processedValue = `'${processedValue}'`;
          break;
      }
    }
    return {
      isValid,
      processedValue,
    };
  }
  return { isValid: false };
}

function validateQuery(query, fieldsMap, depth) {
  let isValid = null;
  depth = depth || 0;

  if (query?.rules) {
    isValid = true;
    isValid &= validateQuery(query.rules, fieldsMap, depth + 1);
  } else if (_.isArray(query)) {
    isValid = depth <= 1 || query.length > 0;
    for (const subItem of query) {
      isValid &= validateQuery(subItem, fieldsMap, depth + 1);
    }
  } else if (_.isPlainObject(query)) {
    return validateField(query, fieldsMap[query.field]).isValid;
  }

  return isValid === null || isValid ? true : false;
}

function unselectInvalidFields(query, fieldsConfig) {
  let isInvalid = false;
  if (_.isArray(query.rules) && query.rules.length) {
    isInvalid |= unselectInvalidFields(query.rules, fieldsConfig);
    query.rules = query.rules.filter((r) => r);
  } else if (_.isArray(query)) {
    for (const subItem of query) {
      isInvalid |= unselectInvalidFields(subItem, fieldsConfig);
    }
  } else if (query.field) {
    // console.log(c, item.field);
    if (!fieldsConfig?.map?.[query.field]) {
      query.field = '~';
      isInvalid |= true;
    } else {
      const fieldData = fieldsConfig.map[query.field];
      if (fieldData?.operators?.length) {
        if (!_.find(fieldData.operators, (o) => o.name === query.operator)) {
          query.operator = fieldData.operators[0].name;
        }
      }
    }
    // if (!_.find(fields, (f) => f.Name === query.field)) {
    //   query.field = '~';
    //   return true;
    // }
  }
  return isInvalid;
}

function removeEmptyCriterias(query, depth) {
  depth = depth || 0;
  if (_.isArray(query.rules)) {
    removeEmptyCriterias(query.rules, depth + 1);
    query.rules = query.rules.filter((r) => r);
    return query.rules.length;
  } else if (_.isArray(query)) {
    for (const index in query) {
      if (removeEmptyCriterias(query[index], depth + 1) === 0) {
        query[index] = null;
      }
    }
  }
}

const QueryUtils = {
  queryCompressionEnabled,
  initialQuery,
  operators,
  operatorsList,
  inputTypes,
  dataTypes,
  dataExtensionOperatorsByDataTypes,
  subscriberOperatorsByDataTypes,
  formatTipByDataTypes,
  parseRawQueryParam,
  createQueryParam,
  validateField,
  validateQuery,
  unselectInvalidFields,
  removeEmptyCriterias,
  validateDateValue,
};

export default QueryUtils;
