import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "./rowCopyFieldDialog.scss";
import { useRef } from "react";

const RowCopyFieldDialog = ({ selectedRowInfo, openDialog, setOpenDialog }) => {
  const textFieldRef = useRef(null);

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const selectText = () => {
    const range = document.createRange();
    range.selectNodeContents(textFieldRef.current);

    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  };

  return (
    <Box
      sx={{
        width: "100%",
      }}
    >
      <div>
        <Dialog
          className="row-copy__field-dialog"
          open={openDialog}
          onClose={handleCloseDialog}
        >
          <DialogTitle>{selectedRowInfo.title}</DialogTitle>
          <DialogContent>
            <DialogContentText component="div">
              <pre ref={textFieldRef} style={{ whiteSpace: "pre-wrap" }}>
                {selectedRowInfo.cell}
              </pre>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button color="primary" onClick={handleCloseDialog}>
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={selectText}>
              Select Field
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
};

export default RowCopyFieldDialog;
