// import _ from 'lodash';
import xmlFormatter from 'xml-formatter';

/**
 * Utility class
 */
class Util {
  /**
   * Format a number with commas
   * @param {number} x
   * @returns {string}
   */
  static numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  /**
   * Determine if the app is in an iframe
   */
  static isInIframe() {
    return window.location !== window.parent.location;
  }

  /**
   * Format XML with indentation
   * @param {string} sourceXml
   * @returns {string}
   */
  static formatXml(sourceXml) {
    return sourceXml ? xmlFormatter(sourceXml, { indentation: ' ' }) : '';
  }
}

export default Util;
