import './BusinessUnitDetails.scss';
import './Audits.scss';
import { Business as BusinessIcon, ContentCopy } from '@mui/icons-material';
import {
  Alert,
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from '@mui/material';
import CloudIcon from '@mui/icons-material/Cloud';
import React, { useMemo, useRef } from 'react';
import ClipboardCopy from '../UI/ClipboardCopy';

import Util from '../lib/util';

/**
 * @callback OnBusinessUnitChange
 * @param {BusinessUnitItem} businessUnit the business unit details
 */

/**
 * @callback OnBusinessUnitError
 * @param {Error} error
 */

/**
 * @param {{onLoad: OnBusinessUnitChange, onError: OnBusinessUnitError}}
 */
const BusinessUnitDetails = ({
  userInfo,
  loading,
  onLoad,
  onError,
  className,
}) => {
  // const isMounted = useMountedState();
  const clipboard = useRef();

  const classeNames = useMemo(() => {
    const value = ['business-unit-details'];
    if (loading) {
      value.push('loading');
    }
    return value;
  }, [loading]);

  return (
    <Box sx={{ position: 'relative' }}>
      <ClipboardCopy ref={clipboard} flashPopperClassName={'audits'} />
      <Box className={classeNames.join(' ')}>
        {userInfo?.organization ? (
          <>
            <Grid
              direction={'row'}
              className="audits"
              style={{
                width: '100%',
                background: ' #f9f9f9',
                borderRadius: '.25rem',
                boxShadow: '0 2px 2px 0 rgb(0 0 0 / 10%)',
                justifyContent: 'space-between',
                border: '1px solid #dddbda',
              }}
              container
            >
              <div className="audits-header">
                <img src="/logo.svg" alt="Data Discovery Studio" />
              </div>
              <Box sx={{ display: 'flex' }}>
                <ListItemAvatar
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Avatar style={{ background: '#00396B' }}>
                    <BusinessIcon />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Grid container className="bu-list-item">
                    <Grid
                      item
                      xs={8}
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '0.3rem',
                      }}
                    >
                      <div className="bu-link">
                        <div className="bu-name">
                          {userInfo?.organization?.businessUnit?.name}
                        </div>
                        <div className="bu-mid">
                          <span
                            className="bu-mid-text"
                            style={{
                              whiteSpace: 'nowrap',
                              display: 'inline-block',
                            }}
                          >
                            {userInfo?.organization?.member_id}{' '}
                            <IconButton
                              className="copy-bu-field"
                              size={'small'}
                              onClick={(evt) => {
                                clipboard.current.copy(
                                  evt,
                                  String(userInfo?.organization?.member_id)
                                );
                                evt.stopPropagation();
                                evt.preventDefault();
                              }}
                            >
                              <ContentCopy />
                            </IconButton>
                          </span>
                        </div>
                      </div>
                    </Grid>
                    {userInfo?.organization?.parentId &&
                    userInfo?.organization?.parentName ? (
                      <Grid item xs={4}>
                        <div className="bu-parent">
                          <div className="bu-name">
                            {userInfo?.organization?.parentName}
                          </div>
                          <div className="bu-mid">
                            <span
                              className="bu-mid-text"
                              style={{
                                whiteSpace: 'nowrap',
                                display: 'inline-block',
                              }}
                            >
                              {userInfo?.organization?.parentId}{' '}
                              <IconButton
                                className="copy-bu-field"
                                size={'small'}
                                onClick={(evt) => {
                                  clipboard.current.copy(
                                    evt,
                                    String(userInfo?.organization?.parentId)
                                  );
                                  evt.stopPropagation();
                                  evt.preventDefault();
                                }}
                              >
                                <ContentCopy />
                              </IconButton>
                            </span>
                          </div>
                        </div>
                      </Grid>
                    ) : (
                      ''
                    )}
                  </Grid>
                </ListItemText>
              </Box>
            </Grid>
          </>
        ) : !loading && userInfo?.authError ? (
          <Alert severity="error">
            {Util.isInIframe() ? (
              <>Your are not signed in. Please refresh the current tab.</>
            ) : (
              <>
                Please <a href="/auth/login">sign in</a>.
              </>
            )}
          </Alert>
        ) : !loading && userInfo?.error ? (
          <Alert severity="error">
            An error occured. If the problem persist, please contact your
            administrator.
          </Alert>
        ) : (
          ''
        )}
      </Box>
      {loading ? (
        <Skeleton
          variant="rectangular"
          width={'100%'}
          height={'100%'}
          style={{
            flex: '1 1',
            width: '100%',
            borderRadius: '0.25rem',
            minHeight: '81px',
            position: 'absolute',
            top: '0',
            left: '0',
            zIndex: '-1',
          }}
        />
      ) : (
        ''
      )}
    </Box>
  );
};

// const useMountedState = () => {
//   const mountedRef = useRef(false);
//   const isMounted = useCallback(() => mountedRef.current, []);
//   useEffect(() => {
//     mountedRef.current = true;

//     return () => {
//       // console.log("unmount");
//       mountedRef.current = false;
//     };
//   }, []);

//   return isMounted;
// };

export default BusinessUnitDetails;
