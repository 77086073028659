import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Routes, Route } from 'react-router-dom';
import { Box } from '@mui/material';
import BrowseDataExtensions from '../Browse/BrowseDataExtensions';
import AppInsights from '../lib/appInsights';

import '../styles/home/home.scss';
import '../styles/dark.scss';
import { DarkModeContext } from '../context/darkModeContext';
import Util from '../lib/util';
import Feedback from '../components/feedback/Feedback';

function App() {
  const { darkMode } = useContext(DarkModeContext);
  const [userInfo, setUserInfo] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const location = useLocation();

  useEffect(() => {
    AppInsights.trackPageView(userInfo);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, userInfo?.user?.preferred_username]);

  useEffect(() => {
    const getUser = async () => {
      setLoadingUser(true);
      await fetch('/auth/me')
        .then(async (res) => {
          if (res.status === 200) {
            return res.json();
          } else if (res.status === 401 || res.status === 403) {
            return { authError: await res.json() };
          } else {
            return { error: await res.json() };
          }
        })
        .then((userInfo) => {
          setUserInfo(userInfo);
        })
        .catch((error) => {
          setLoadingUser(false);
          setUserInfo({ error: {} });
          console.error(error);
        })
        .finally(() => {
          setLoadingUser(false);
        });

      setLoadingUser(false);
    };
    getUser();
  }, [location.pathname]);

  const routeIsActive = (route) => {
    return (
      route &&
      (location.pathname === route || location.pathname.startsWith(`${route}/`))
    );
  };

  return (
    <div className={darkMode ? 'app dark' : 'app'}>
      <div className="home">
        <div className="home-container">
          <div className={`main${Util.isInIframe() ? ' appexchange' : ''}`}>
            <Box sx={{ m: 2 }}>
              <Routes>
                <Route path="/">
                  <Route
                    index={true}
                    element={
                      <BrowseDataExtensions
                        userInfo={userInfo}
                        loadingUser={loadingUser}
                      />
                    }
                  />
                  <Route
                    path="browse/e"
                    element={
                      <BrowseDataExtensions
                        userInfo={userInfo}
                        loadingUser={loadingUser}
                      />
                    }
                  />
                  <Route
                    path="browse/e/:name"
                    element={
                      <BrowseDataExtensions
                        userInfo={userInfo}
                        loadingUser={loadingUser}
                      />
                    }
                  />
                </Route>
              </Routes>
            </Box>
          </div>
          <Feedback user={userInfo} />
        </div>
      </div>
    </div>
  );
}

export default App;
