import './Browse.scss';
import { createFilterOptions } from '@mui/material/Autocomplete';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Autocomplete,
  Button,
  Checkbox,
  Dialog,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Tab,
  Tabs,
  TextField,
  Typography,
} from '@mui/material';
import {
  Close,
  ContentCopy,
  Download,
  ExpandMore,
  Info,
} from '@mui/icons-material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import { useNavigate, useSearchParams } from '../State/Navigation';
import { DataExtensionsQueryBuilder } from './DataExtensionsQueryBuilder';
import _ from 'lodash';
import RowData from './RowData';
import { Box } from '@mui/system';
import CustomPopover from '../UI/CustomPopover';
import BusinessUnitDetails from '../Audits/BusinessUnitDetails';
import AppInsights from '../lib/appInsights';
import ClipboardCopy from '../UI/ClipboardCopy';
import SFMC from '../lib/sfmc';
import { format as sqlFormat } from 'sql-formatter';

const reduceProgress = (state, action) => {
  const newState = _.cloneDeep(state);
  let count = Number(newState.count) || 0;
  for (const key in action) {
    switch (action[key]) {
      case true:
        newState[key] = true;
        newState.count = ++count;
        break;
      case false:
        delete newState[key];
        newState.count = --count;
        break;
      default:
        break;
    }
  }
  return newState;
};

/** *
 * @param {Object} props
 * @param {SfmcUserInfo} props.userInfo
 */
export default function BrowseDataExtensions({ userInfo, loadingUser }) {
  const params = useParams();
  const navigate = useNavigate();
  const [dataExtensionSelectEvent, setDataExtensionSelectEvent] =
    useState(null);
  const [progress, dispatchProgress] = useReducer(reduceProgress, { count: 0 });
  const [dataExtensions, setDataExtensions] = useState(null);
  const [searchParams] = useSearchParams();
  const [selectedDataExtension, setSelectedDataExtension] = useState(null);
  const [searchResults, setSearchResults] = useState(null);
  const [currentName, setCurrentName] = useState('');
  const [currentQuery, setCurrentQuery] = useState({});
  const [lastQuery, setLastQuery] = useState({});
  const [openDataExtensionDialog, setOpenDataExtensionDialog] =
    React.useState(false);

  const downloadRef = useRef();

  const firstUpdate = useRef(true);

  const handleClickOpenDataExtensionDialog = () => {
    setOpenDataExtensionDialog(true);
    AppInsights.trackEvent(userInfo, {
      name: 'Click::Additional information',
    });
  };

  const handleCloseDataExtensionDialog = (string) => {
    setOpenDataExtensionDialog(false);
  };

  /**
   * @param {string} input
   */
  const renderMatch = (input) => {
    if (input && currentName) {
      const lowercaseFilter = currentName.toLowerCase();
      const indices = _.toArray(
        String(input).matchAll(new RegExp(_.escapeRegExp(currentName), 'gi'))
      )
        .flatMap((m) => [m.index, m.index + currentName.length])
        .filter((index) => index > 0);
      indices.push(input.length);

      let lastIndex = 0;
      return React.Children.toArray(
        indices.map((index) => {
          const part = String(input).substring(lastIndex, index);
          lastIndex = index;
          return part.toLowerCase() === lowercaseFilter ? (
            <span className="search-match">{part}</span>
          ) : (
            <span className="search-unmatched">{part}</span>
          );
        })
      );
    }
    return input;
  };

  useEffect(() => {
    if (!userInfo?.user?.preferred_username) {
      firstUpdate.current = false;
      return;
    }
    const getDE = async () => {
      try {
        dispatchProgress({ selectingDE: true });
        if (params.name) {
          const result = await fetch(
            `/api/sfmc/dataextension/name/${params.name}?getFields=1&getFolderTree=1&properties=*`
          );
          if (result.status !== 200) {
            setSearchResults({
              results: null,
              error: result.status,
              dataExtension: null,
            });
            setSelectedDataExtension({ Name: params.name, invalid: true });
            setCurrentName(params.name);
          } else {
            const obj = await result.json();
            const de = obj?.results?.[0];
            setSelectedDataExtension(de);
            setCurrentName(de.Name);
          }
        } else {
          setSelectedDataExtension(null);
          setCurrentName(null);
        }
      } catch (error) {
        console.error(error);
        setSearchResults({
          results: null,
          error,
          dataExtension: null,
        });
      } finally {
        dispatchProgress({ selectingDE: false });
      }
    };
    if (
      params.name?.toLowerCase() !== selectedDataExtension?.Name?.toLowerCase()
    ) {
      getDE();
    }
  }, [
    userInfo?.user?.preferred_username,
    params.name,
    selectedDataExtension,
    searchParams,
    dataExtensionSelectEvent,
  ]);

  const selectDataExtension = (dataExtension) => {
    // dataExtensionName = dataExtensions?.find(
    //   (de) => de.Name?.toLowerCase() === dataExtensionName?.toLowerCase()
    // )?.Name;
    if (dataExtension?.Name) {
      if (
        params.name === dataExtension?.Name ||
        selectedDataExtension?.invalid
      ) {
        setDataExtensionSelectEvent(new Date());
      }
      navigate({
        pathname: dataExtension.Name
          ? `/browse/e/${dataExtension.Name}`
          : `/browse/e`,
        search: `?${searchParams.toString()}`,
      });
    }
  };

  useEffect(() => {
    const getDEs = async () => {
      // if (firstUpdate.current) {
      if (!userInfo?.user?.preferred_username) {
        firstUpdate.current = false;
        return;
      }
      try {
        dispatchProgress({ loadingDEs: true });
        const result = await fetch(
          `/api/sfmc/dataextension?includeAllSubscribers=true`
        );
        if (result.status !== 200) {
          setSearchResults({
            results: null,
            error: result.status,
            dataExtension: null,
          });
        } else {
          const des = (await result.json())?.results || [];
          setDataExtensions(des);
        }
      } catch (error) {
        console.error(error);
        setSearchResults({
          results: null,
          error,
          dataExtension: null,
        });
      } finally {
        dispatchProgress({ loadingDEs: false });
      }
    };
    getDEs();
  }, [userInfo?.user?.preferred_username]);

  const deList = useMemo(() => {
    // const names = dataExtensions?.map((de) => de.Name) || [];
    const list = _.sortBy(
      dataExtensions,
      (dataExtension) => {
        const ix = dataExtension.Name?.toLowerCase().indexOf(
          currentName?.toLowerCase()
        );
        return ix >= 0 ? ix : 1e9;
      },
      (dataExtension) => dataExtension.Name?.toLowerCase(),
      (dataExtension) => dataExtension.Folder?.Path?.toLowerCase(),
      (dataExtension) => Number(dataExtension.Client?.ID) || 1e9
    );
    return list;
  }, [dataExtensions, currentName]);

  const downloadOptions = useMemo(() => {
    if (!selectedDataExtension?.CustomerKey) {
      return;
    }

    // NOTE: we build the querystring this way to prevent the $ character from being encoded.
    let uri;
    const queryItems = [];

    if (/^_subscribers$/i.test(selectedDataExtension?.CustomerKey)) {
      uri = `/api/sfmc/subscribers/csv`;
      if (currentQuery?.sql) {
        queryItems.push(
          `filter=${encodeURIComponent(JSON.stringify(currentQuery.query))}`
        );
      }
    } else {
      queryItems.push(`$page=${1}`);
      uri = `/api/sfmc/dataextension/csv/key/${selectedDataExtension.CustomerKey}`;
      if (currentQuery?.sql) {
        queryItems.push(`$filter=${encodeURIComponent(currentQuery.sql)}`);
      }
    }

    return { uri, queryItems };
  }, [selectedDataExtension, currentQuery]);

  const search = useCallback(
    async ({ searchQuery, isSearching, page, orderBy }) => {
      if (isSearching) {
        setLastQuery(searchQuery);
      }

      AppInsights.trackEvent(userInfo, {
        name: 'Click::Search',
      });

      const fromPaging =
        (typeof page === 'number' && page) ||
        (typeof orderBy === 'string' && orderBy)
          ? true
          : false;
      try {
        if (fromPaging) {
          dispatchProgress({ paging: true });
        } else {
          dispatchProgress({ searching: true });
        }
        page = typeof page === 'number' ? page || 1 : 1;
        orderBy = orderBy && typeof orderBy === 'string' ? orderBy : '';

        if (/^_subscribers$/i.test(selectedDataExtension?.CustomerKey)) {
          const uri = `/api/sfmc/subscribers/json?asDataExtension=true`;

          const searchUri = searchQuery.sql
            ? `${uri}&filter=${encodeURIComponent(
                JSON.stringify(searchQuery.query)
              )}`
            : uri;
          const response = await fetch(searchUri);
          const results = (await response.json()) || {};
          results.status = results.status || response.status;
          const error = results.status === 200 ? null : results.status;
          setSearchResults({
            results: results.status === 200 ? results : null,
            error,
            errorSearching:
              results.status === 401 ? results.message : results.status !== 200,
            dataExtension: selectedDataExtension,
            searchQuery,
            orderBy,
          });
        } else {
          const uri = `/api/sfmc/dataextension/json/key/${selectedDataExtension.CustomerKey}`;

          // NOTE: we build the querystring this way to prevent the $ character from being encoded.
          const queryItems = [`?$page=${page}&$pageSize=50`];
          if (orderBy) {
            queryItems.push(`$orderBy=${encodeURIComponent(orderBy)}`);
          }
          if (searchQuery.sql) {
            queryItems.push(`$filter=${encodeURIComponent(searchQuery.sql)}`);
          }

          const searchUri = uri + queryItems.join('&');
          const response = await fetch(searchUri);
          const results = (await response.json()) || {};
          results.status = results.status || response.status;
          const error = results.status === 200 ? null : results.status;
          setSearchResults({
            results: results.status === 200 ? results : null,
            error,
            errorSearching:
              results.status === 401 ? results.message : results.status !== 200,
            dataExtension: selectedDataExtension,
            searchQuery,
            orderBy,
          });
        }
      } catch (error) {
        console.error(error);
        setSearchResults({
          results: null,
          error,
          errorSearching: true,
          dataExtension: selectedDataExtension,
        });
      } finally {
        if (fromPaging) {
          dispatchProgress({ paging: false });
        } else {
          dispatchProgress({ searching: false });
        }
      }
    },
    [selectedDataExtension, userInfo]
  );

  const selectionCssClasses = useMemo(() => {
    if (progress.count) {
      return 'de-selection busy';
    } else if (
      !selectedDataExtension?.invalid &&
      selectedDataExtension?.Name &&
      selectedDataExtension?.Name?.toLowerCase() === currentName?.toLowerCase()
    ) {
      return 'de-selection selected';
    } else if (
      (!selectedDataExtension?.invalid || currentName !== params.name) &&
      _.find(
        dataExtensions,
        (de) => de.Name?.toLowerCase() === currentName?.toLowerCase()
      )
    ) {
      return 'de-selection valid';
    } else if (
      selectedDataExtension?.Name &&
      selectedDataExtension?.Name?.toLowerCase() !== currentName?.toLowerCase()
    ) {
      return 'de-selection invalid';
    } else if (selectedDataExtension?.invalid) {
      return 'de-selection invalid';
    }
    return 'de-selection';
  }, [
    progress.count,
    selectedDataExtension,
    currentName,
    dataExtensions,
    params.name,
  ]);

  const filterOptions = createFilterOptions({
    stringify: (option) => {
      const folderPath = option.Folder?.Path?.split('/')?.slice(1);
      return [option.Name, ...(folderPath || [])].join(' ').trim();
    },
  });

  return (
    <>
      <div className="audits browse">
        <BusinessUnitDetails
          loading={loadingUser}
          showSyncDate={false}
          userInfo={userInfo}
        />
        <hr
          className="bu-details-seperator"
          hidden={userInfo?.organisation?.member_id ? false : true}
        />
        <Box sx={{ position: 'relative' }}>
          {progress.loadingDEs && userInfo?.user ? (
            <Skeleton
              variant="rectangular"
              width={'100%'}
              height={'100%'}
              style={{
                flex: '1 1',
                width: '100%',
                borderRadius: '0.25rem',
                minHeight: '344.5px',
                position: 'absolute',
                top: '0',
                left: '0',
                zIndex: '-1',
              }}
            />
          ) : (
            ''
          )}
        </Box>
        {dataExtensions ? (
          <>
            <Grid
              direction={'column'}
              spacing={1}
              container
              className="data-extension"
            >
              <Grid item>
                <Grid direction={'row'} container spacing={1}>
                  <Grid item>
                    <Autocomplete
                      // disablePortal
                      className={selectionCssClasses}
                      readOnly={progress.count > 0}
                      // freeSolo
                      openOnFocus={false}
                      onSelect={(event) => {
                        setCurrentName(event.target.value);
                      }}
                      isOptionEqualToValue={(option, value) => {
                        return option && value && option.Name === value?.Name;
                      }}
                      options={deList}
                      value={selectedDataExtension}
                      onChange={(event, option) => {
                        selectDataExtension(option);
                      }}
                      filterOptions={filterOptions}
                      getOptionLabel={(option) => {
                        return option.Name || '';
                      }}
                      renderOption={(props, option) => (
                        <AutocompleteOption
                          key={JSON.stringify({
                            n: option?.Name,
                            k: option?.CustomerKey,
                            i: option?.Client?.ID,
                          })}
                          renderMatch={renderMatch}
                          props={props}
                          option={option}
                        />
                      )}
                      renderInput={(inputParams) => (
                        <TextField
                          {...inputParams}
                          placeholder="Start typing or press arrow keys to view the full list"
                          label="Data extension"
                          onMouseDownCapture={(e) => e.stopPropagation()}
                          onClick={(event) => {
                            if (currentName !== '') {
                              if (
                                (event.target &&
                                  event.target.dataset &&
                                  event.target.dataset.testid ===
                                    'CloseIcon') ||
                                (event.target &&
                                  event.target.parentNode.dataset &&
                                  event.target.parentNode.dataset.testid ===
                                    'CloseIcon') ||
                                (event.target &&
                                  event.target.firstElementChild &&
                                  event.target.firstElementChild.dataset
                                    .testid === 'CloseIcon')
                              ) {
                                navigate(`/browse/e`);
                              }
                            }
                          }}
                        />
                      )}
                    />
                  </Grid>
                  {progress.selectingDE ? (
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        marginLeft: '1rem',
                        position: 'relative',
                        minWidth: '240px',
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        width={'100%'}
                        style={{
                          width: '100%',
                        }}
                      />
                      <Skeleton
                        animation="wave"
                        width={'100%'}
                        style={{
                          width: '100%',
                        }}
                      />
                    </Box>
                  ) : (
                    ''
                  )}
                  {selectedDataExtension?.CustomerKey &&
                  !/^_subscribers$/i.test(selectedDataExtension?.CustomerKey) &&
                  !progress.loadingDEs &&
                  !progress.selectingDE ? (
                    <Grid className="dataextension-details" item>
                      <List>
                        <ListItem>
                          <span>
                            <strong>Customer key: </strong>
                            {selectedDataExtension?.CustomerKey || 'N/A'}
                          </span>
                          <IconButton
                            className="darkHover"
                            onClick={handleClickOpenDataExtensionDialog}
                            title="Additional information"
                          >
                            <Info />
                          </IconButton>
                          <DataExtensionDetails
                            selectedDataExtension={selectedDataExtension}
                            sqlQuery={currentQuery?.sql}
                            open={openDataExtensionDialog}
                            onClose={handleCloseDataExtensionDialog}
                            userInfo={userInfo}
                          />
                        </ListItem>
                        <ListItem>
                          <ListItemText>
                            <strong>Path: </strong>
                            {selectedDataExtension?.Folder?.Path || 'N/A'}
                          </ListItemText>
                        </ListItem>
                      </List>
                    </Grid>
                  ) : (
                    ''
                  )}

                  {/^_subscribers$/i.test(selectedDataExtension?.CustomerKey) &&
                  !progress.loadingDEs &&
                  !progress.selectingDE ? (
                    <Grid className="subscribers-details" item maxWidth={600}>
                      <Alert
                        severity="info"
                        sx={{ padding: '1px 3px 1px 3px' }}
                      >
                        Different operators are available for retrieving
                        subscribers. Sorting and paging are not supported. To
                        retrieve more records, please use the Download button.
                      </Alert>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Grid>
              </Grid>
              <Grid className="query-grid-item" item>
                <fieldset>
                  <legend>Query</legend>
                  <DataExtensionsQueryBuilder
                    userInfo={userInfo}
                    dataExtension={selectedDataExtension}
                    onChange={(q) => setCurrentQuery(q)}
                  />
                </fieldset>
              </Grid>
              <Grid className="query-grid-search" item>
                <Grid direction={'row'} container spacing={1}>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      // endIcon={<SearchIcon />}
                      onClick={(options) =>
                        search({ isSearching: true, searchQuery: currentQuery })
                      }
                      disabled={
                        progress.count > 0 ||
                        currentQuery.sql === '!' ||
                        selectedDataExtension?.invalid ||
                        !selectedDataExtension?.Name ||
                        selectedDataExtension?.Name !== currentName
                      }
                    >
                      Search
                    </Button>
                  </Grid>
                  <Grid item>
                    <CustomPopover
                      ref={downloadRef}
                      buttonText={'Download'}
                      buttonSize="small"
                      buttonDisabled={
                        progress.count > 0 ||
                        currentQuery.sql === '!' ||
                        selectedDataExtension?.invalid ||
                        !selectedDataExtension?.Name ||
                        selectedDataExtension?.Name !== currentName
                      }
                      component={
                        <>
                          <DownloadPanel
                            userInfo={userInfo}
                            options={downloadOptions}
                            onDismiss={() => downloadRef?.current?.close()}
                          />
                        </>
                      }
                    />
                  </Grid>
                  <Grid item className="orderBy-warning-grid">
                    {searchResults?.orderBy && !progress.searching ? (
                      <Alert color="warning" className="orderBy-warning">
                        When using the sort feature, the API only allow querying
                        the top 100,000 rows.
                      </Alert>
                    ) : (
                      ''
                    )}
                  </Grid>
                </Grid>
              </Grid>
              {searchResults?.results?.count > 0 || progress.searching ? (
                <Grid className="query-grid-results" item>
                  <RowData
                    userInfo={userInfo}
                    input={searchResults}
                    loading={progress.searching}
                    paging={progress.paging}
                    onChange={(options) => {
                      search({
                        ...options,
                        ...{ isSearching: false, searchQuery: lastQuery },
                      });
                    }}
                  />
                </Grid>
              ) : (
                ''
              )}
              {searchResults?.error &&
              (searchResults?.errorSearching ||
                selectedDataExtension?.invalid) ? (
                <Grid item>
                  <Alert severity="error">
                    {searchResults?.error === 401 ||
                    searchResults?.error === 403 ? (
                      <>
                        {
                          'Insufficient permissions. Not all data extensions can be retrieved using the API.'
                        }
                        {searchResults?.errorSearching ? (
                          <> {searchResults?.errorSearching}</>
                        ) : (
                          ''
                        )}
                        {/20002/.test(searchResults?.errorSearching)
                          ? ' The REST API cannot retrieve records from this data extension with the installed package.'
                          : ''}
                      </>
                    ) : (
                      <>
                        {
                          'An error occured. If the problem persist, please contact your administrator.'
                        }
                        {searchResults?.errorSearching ? (
                          <>
                            <ul>
                              <li>
                                {
                                  'Some system data extensions, like _MobileAddress, cannot be retrieved.'
                                }
                              </li>
                              <li>
                                {
                                  'The API may also not be able to fetch results from very large data extensions.'
                                }
                              </li>
                              <li>
                                {' '}
                                {`Tip: Try using the 'between' operator instead of <, >, =, !=. `}
                              </li>
                            </ul>
                          </>
                        ) : (
                          ''
                        )}
                      </>
                    )}
                  </Alert>
                </Grid>
              ) : searchResults?.results?.count === 0 &&
                selectedDataExtension?.Name ===
                  searchResults?.results?.customObjectName ? (
                <Grid item>
                  <Alert severity="warning">
                    No records match the search criteria.
                  </Alert>
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </>
        ) : userInfo?.user && searchResults?.error ? (
          <Alert severity="error">
            {searchResults?.error === 401 || searchResults?.error === 403
              ? 'Insufficient permissions.'
              : 'An error occured. If the problem persist, please contact your administrator.'}
          </Alert>
        ) : (
          ''
        )}
      </div>
    </>
  );
}

function DownloadPanel({ options, onDismiss, userInfo }) {
  const [nbItems, setNbItems] = useState(50);
  const [checked, setChecked] = useState(false);
  onDismiss = onDismiss || (() => undefined);
  const downloadUri = useMemo(() => {
    const queryItems = _.cloneDeep(options.queryItems || []);

    if (/^[/]api[/]sfmc[/]subscribers[/]csv[/]{0,1}/.test(options.uri)) {
      queryItems.push(`batchSize=${checked ? 0 : nbItems}`);
    } else {
      queryItems.push(`$pageSize=${checked ? 0 : nbItems}`);
    }

    const uri = options.uri + '?' + queryItems.join('&');
    return uri;
  }, [nbItems, options, checked]);
  return (
    <Box className="browse download-panel">
      <Grid spacing={1} container direction={'column'}>
        <Grid item>
          <Grid container direction={'row'} spacing={1}>
            <Grid item>
              <FormGroup>
                <FormControlLabel
                  style={{ margin: 0 }}
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={(event) => setChecked(event.target.checked)}
                    />
                  }
                  label="all rows"
                />
              </FormGroup>
            </Grid>
            <Grid item>
              <Typography
                component="div"
                style={{
                  lineHeight: '40px',
                  verticalAlign: 'middle',
                }}
              >
                or
              </Typography>
            </Grid>
            <Grid item>
              <TextField
                disabled={checked}
                value={nbItems}
                onChange={(event) => {
                  setNbItems(event.target.value);
                }}
                InputProps={{
                  style: { width: '150px', height: '40px' },
                }}
                id="download-amount"
                label="Number of rows"
                type="number"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item>
              <Button
                disabled={downloadUri ? false : true}
                component="a"
                onClick={() =>
                  AppInsights.trackEvent(userInfo, {
                    name: 'Click::Download CSV',
                    measurements: { nbRows: checked ? -1 : nbItems },
                  })
                }
                href={downloadUri}
                target={'top'}
                style={{
                  lineHeight: '30px',
                  verticalAlign: 'middle',
                }}
                variant="contained"
                size="small"
                color="secondary"
              >
                Download CSV
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item>
          <Alert
            variant="outlined"
            color="warning"
            className="lookup-download-warning"
            style={{ maxWidth: '400px' }}
          >
            {/* <p style={{ 'marginBottom': '5px' }}>Maximum: 10,000 rows.</p> */}
            <p>
              Downloading via the API is <strong>extremely</strong> slow and
              resource intensive. Please use the export feature in SFMC if you
              have more than a few thousand rows to export.
            </p>
            <p>
              If SFMC does not answer within the first 30 seconds, the download
              will fail.
            </p>
            <p>
              If the subsequent pages are not returned by SFMC within 55 seconds
              of each other, the dowload will fail.
            </p>
          </Alert>
        </Grid>
        <Grid item sx={{ textAlign: 'right' }}>
          <Button color="secondary" size="small" onClick={onDismiss}>
            Dismiss
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function DataExtensionDetails({
  selectedDataExtension,
  sqlQuery,
  onClose,
  open,
  userInfo,
}) {
  const [tabValue, setTabValue] = useState(0);

  const onCloseHandler = onClose;
  onClose = () => {
    onCloseHandler?.();
    setTabValue(0);
  };

  const tabs = [
    {
      name: 'Details',
      component: (
        <FieldsDefinition selectedDataExtension={selectedDataExtension} />
      ),
    },
    {
      name: 'Helpers',
      component: (
        <DataExtensionHelpers
          selectedDataExtension={selectedDataExtension}
          sqlQuery={sqlQuery}
          userInfo={userInfo}
        />
      ),
    },
  ];

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
    AppInsights.trackEvent(userInfo, {
      name: `TabChange::Additional information::${tabs[newValue].name}`,
    });
  };
  return (
    <Dialog
      onBackdropClick={onClose}
      className="DataExtensionDetails"
      onClose={onClose}
      open={open}
    >
      <div className="browse dataExtensionPopover">
        <Box sx={{ width: '100%' }}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              aria-label="Data extension details"
            >
              {React.Children.toArray(
                tabs.map((tab, index) => (
                  <Tab label={tab.name} {...a11yProps(index)} />
                ))
              )}
            </Tabs>
            <IconButton
              aria-label="close"
              onClick={onClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <Close />
            </IconButton>
          </Box>
          {React.Children.toArray(
            tabs.map((tab, index) => (
              <TabPanel value={tabValue} index={index}>
                {tab.component}
              </TabPanel>
            ))
          )}
        </Box>
      </div>
    </Dialog>
  );
}

function DataExtensionHelpers({ selectedDataExtension, sqlQuery, userInfo }) {
  const clipboard = useRef();

  return (
    <Box className="browse DataExtensionHelpers">
      <Accordion defaultExpanded={true}>
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography>SQL select statement</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SqlSelectStatement
            selectedDataExtension={selectedDataExtension}
            clipboard={clipboard}
            userInfo={userInfo}
            sqlQuery={sqlQuery}
          />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

function SqlSelectStatement({
  clipboard,
  selectedDataExtension,
  sqlQuery,
  userInfo,
}) {
  let sqlSelectStatement = selectedDataExtension
    ? SFMC.getSelectStatement(selectedDataExtension)
    : '';

  if (sqlQuery && typeof sqlQuery === 'string' && sqlQuery !== '!') {
    try {
      sqlSelectStatement = `${sqlSelectStatement}\nwhere ${sqlFormat(sqlQuery, {
        language: 'tsql',
        indent: '  ',
      })}`;
    } catch (e) {
      console.error(e.message);
    }
  }

  return (
    <div className="browse DataExtensionHelpers">
      <ClipboardCopy
        ref={clipboard}
        flashPopperClassName="select-sql-statement-popper"
        flashPopperPlacement="left"
      />
      <pre className="de-select-statement">{sqlSelectStatement}</pre>
      <div className="de-select-statement-buttons">
        <IconButton
          onClick={() =>
            AppInsights.trackEvent(userInfo, {
              name: 'Click::Download SQL select statement',
            })
          }
          href={`data:text/plain,${encodeURIComponent(sqlSelectStatement)}`}
          download={`${selectedDataExtension?.Name}.sql`}
          size={'small'}
        >
          <Download className="darkHover" />
        </IconButton>
        <IconButton
          size={'small'}
          className="de-select-statement-buttons__copy"
          onClick={(evt) => {
            AppInsights.trackEvent(userInfo, {
              name: 'Click::Copy SQL select statement',
            });
            clipboard.current.copy(evt, String(sqlSelectStatement));
            evt.stopPropagation();
            evt.preventDefault();
          }}
        >
          <ContentCopy className="darkHover" />
        </IconButton>
      </div>
    </div>
  );
}

function FieldsDefinition({ selectedDataExtension }) {
  return (
    <>
      <strong class="fieldTitle">Fields</strong>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Type</th>
            <th>Default value</th>
            <th>Required</th>
            <th>Primary key</th>
          </tr>
        </thead>
        <tbody>
          {selectedDataExtension?.Fields?.length
            ? React.Children.toArray(
                _.sortBy(
                  selectedDataExtension.Fields,
                  (f) => (f.IsPrimaryKey === 'true' ? 0 : 1),
                  (f) => f.Name.toLowerCase()
                ).map((field) => (
                  <tr
                    className={
                      field.IsPrimaryKey === 'true'
                        ? 'pk'
                        : field.IsRequired === 'true'
                        ? 'required'
                        : 'std'
                    }
                  >
                    <td className="fieldName">{field.Name}</td>
                    <td className="fieldType">{field.FieldType}</td>
                    <td className="fieldDefault">{field.DefaultValue}</td>
                    <td className="fieldRequired">
                      {field.IsRequired === 'true' ? 'Yes' : 'No'}
                    </td>
                    <td className="fieldPK">
                      {field.IsPrimaryKey === 'true' ? 'Yes' : 'No'}
                    </td>
                  </tr>
                ))
              )
            : ''}
        </tbody>
      </table>
    </>
  );
}

function AutocompleteOption({ props, option, renderMatch }) {
  const pathParts = option.Folder?.Path?.split('/');
  const pathPrefix = pathParts?.[0];
  pathParts?.shift();
  const pathSuffix =
    pathPrefix && pathParts?.length
      ? renderMatch('/' + pathParts?.join('/'))
      : '';
  return (
    <li {...props}>
      <Box className="autocomplete-option-item">
        {option.Folder?.Path ? (
          <>
            <em
              style={{
                fontSize: '0.8rem',
                marginRight: '0.5rem',
              }}
            >
              {pathPrefix}
              {pathSuffix}
            </em>
            <br />
          </>
        ) : (
          ''
        )}
        {renderMatch(option.Name)}
      </Box>
    </li>
  );
}
