import _ from 'lodash';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

// /** @typedef {import('@microsoft/applicationinsights-web').IEventTelemetry} IEventTelemetry */
// /** @typedef {import('@microsoft/applicationinsights-web').ICustomProperties} ICustomProperties */

const appInsightsConfig = document.getElementById('appInsightsConfig');
const appInsightsConnectionString = appInsightsConfig.getAttribute(
  'data-appInsightsConnectionString'
);
const appInsightsCloudRoleInstance = appInsightsConfig.getAttribute(
  'data-appInsightsCloudRoleInstance'
);

const appInsightsCSValidator =
  /^InstrumentationKey=[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12};IngestionEndpoint=https:[/][/][a-z0-9_.-]+.azure.com[/]{0,1}(;LiveEndpoint=https:[/][/][a-z0-9_-]+.livediagnostics.monitor.azure.com[/]{0,1}){0,1}/i;
const appInsightsCS = (() => {
  if (appInsightsCSValidator.test(appInsightsConnectionString)) {
    return appInsightsConnectionString;
  }
})();

const cloudRoleInstance =
  appInsightsCloudRoleInstance && !/^%|^%/.test(appInsightsCloudRoleInstance)
    ? appInsightsCloudRoleInstance
    : window.location.host;

const appInsights = appInsightsCS
  ? new ApplicationInsights({
      config: {
        connectionString: appInsightsCS,
        disableCorrelationHeaders: false,
        enableCorsCorrelation: true,
        maxBatchInterval: 0,
        disableFetchTracking: false,
      },
    })
  : null;
if (appInsights) {
  appInsights.loadAppInsights();

  appInsights.addTelemetryInitializer((envelope) => {
    envelope.tags = envelope.tags || [];
    envelope.tags.push({
      'ai.cloud.role': 'Data Discovery Studio',
      'ai.cloud.roleInstance': cloudRoleInstance,
    });
  });
}

/**
 * Application Insights utility class
 */
class AppInsights {
  static noop() {}
  /**
   * Tracks page views
   * @param {SfmcUserInfo} userInfo as returned by '/auth/me'
   * @returns {void}
   */
  static trackPageView(userInfo) {
    try {
      if (appInsights && userInfo?.user?.sub) {
        const properties = {
          userid: userInfo.user.sub,
          mid: userInfo?.organization?.member_id,
          eid: userInfo?.organization?.enterprise_id,
          enterpriseName: userInfo?.organization?.enterprise_name,
          businessUnitName: userInfo?.organization?.businessUnit?.name,
        };
        const uri = window.location.href.split('?')[0];
        appInsights.trackPageView({ uri, isLoggedIn: true, properties });
        appInsights.flush();
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Tracks events
   * @param {SfmcUserInfo} userInfo as returned by '/auth/me'
   * @param {import('@microsoft/applicationinsights-web').IEventTelemetry} event event params
   * @returns {void}
   */
  static trackEvent(userInfo, event) {
    try {
      if (appInsights && userInfo?.user?.sub) {
        event = _.merge({}, event, {
          properties: {
            userid: userInfo.user.sub,
            mid: userInfo?.organization?.member_id,
            eid: userInfo?.organization?.enterprise_id,
            enterpriseName: userInfo?.organization?.enterprise_name,
            businessUnitName: userInfo?.organization?.businessUnit?.name,
            url: window.location.href.split('?')[0],
            ...event.properties,
          },
        });
        appInsights.trackEvent(event);
        appInsights.flush();
        // console.log('app insights event', event);
      }
    } catch (e) {
      console.error(e);
    }
  }
}

export default AppInsights;
