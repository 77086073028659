import { createContext, useReducer } from 'react';
import DarkModeReducer from './darkModeReducer';

// console.log(
//   localStorage.getItem('darkMode') === 'true' ? 'darkMode' : 'lightMode'
// );

const INITIAL_STATE = {
  darkMode: localStorage.getItem('darkMode') === 'true' ? true : false,
};

export const DarkModeContext = createContext(INITIAL_STATE);

export const DarkModeContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(DarkModeReducer, INITIAL_STATE);

  return (
    <DarkModeContext.Provider value={{ darkMode: state.darkMode, dispatch }}>
      {children}
    </DarkModeContext.Provider>
  );
};
