import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import './feedback.scss';
import { Tooltip } from '@mui/material';
import axios from 'axios';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

function BootstrapDialogTitle(props) {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const Feedback = ({ user }) => {
  const [open, setOpen] = useState(false);
  const [optionValue, setOptionValue] = useState('moderate');
  const [textareaValue, setTextareaValue] = useState('');

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const sendFeedbackRequest = async ({
    feedback,
    sentiment,
    url,
    enterpriseName,
    enterpriseId,
    memberId,
  }) => {
    try {
      let config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
          Accept: 'application/json',
        },
      };
      const res = await axios.post(
        '/api/feedbacks',
        {
          feedback,
          sentiment,
          url,
          enterpriseName,
          enterpriseId,
          memberId,
        },
        config
      );
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const url = window.location.href;
    let feedback = e.target[3].value ?? '';

    const form = {
      feedback: feedback,
      sentiment: optionValue,
      url: url,
      enterpriseName: user.organization.enterprise_name,
      enterpriseId: user.organization.enterprise_id,
      memberId: user.organization.member_id,
    };

    sendFeedbackRequest(form);

    setTextareaValue('');
    setOpen(false);
    setOptionValue('moderate');
  };

  const handleOptionChange = (e) => {
    setOptionValue(e.target.value);
  };

  const handleTextareaChange = (e) => {
    setTextareaValue(e.target.value);
  };

  return (
    <div>
      <Tooltip title="Feedback">
        <IconButton
          className="feedback-icon"
          color="primary"
          aria-label="Feedback"
          onClick={handleClickOpen}
        >
          <QuestionAnswerIcon />
        </IconButton>
      </Tooltip>
      <BootstrapDialog
        className="feedback-dialog"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogContent dividers>
          <form className="feedback" onSubmit={(e) => handleFormSubmit(e)}>
            <span className="feedback__title">
              How was your overall experience with us?
            </span>
            <div className="feedback__options">
              <div className="feedback__options__radio">
                <label
                  className={
                    optionValue === 'unhappy'
                      ? 'feedback__options__radio__checked'
                      : ''
                  }
                >
                  <SentimentVeryDissatisfiedIcon />
                  <input
                    type="radio"
                    value="unhappy"
                    checked={optionValue === 'unhappy'}
                    onChange={handleOptionChange}
                  />
                </label>
              </div>
              <div className="feedback__options__radio">
                <label
                  className={
                    optionValue === 'moderate'
                      ? 'feedback__options__radio__checked'
                      : ''
                  }
                >
                  <SentimentSatisfiedIcon />
                  <input
                    type="radio"
                    value="moderate"
                    checked={optionValue === 'moderate'}
                    onChange={handleOptionChange}
                  />
                </label>
              </div>
              <div className="feedback__options__radio">
                <label
                  className={
                    optionValue === 'happy'
                      ? 'feedback__options__radio__checked'
                      : ''
                  }
                >
                  <InsertEmoticonIcon />
                  <input
                    type="radio"
                    value="happy"
                    checked={optionValue === 'happy'}
                    onChange={handleOptionChange}
                  />
                </label>
              </div>
            </div>
            <label>
              Tell us how we can improve
              <textarea
                value={textareaValue}
                onChange={handleTextareaChange}
                placeholder="Write your message here"
              />
            </label>
            <div className="feedback__options__checkbox">
              <label className="feedback__options__checkbox__label">
                <span>This feedback will be sent anonymously.</span>
              </label>
            </div>
            <DialogActions>
              <div className="feedback__bottom">
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="contained"
                  autoFocus
                  onClick={handleClose}
                >
                  Submit
                </Button>
              </div>
            </DialogActions>
          </form>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
};

export default Feedback;
