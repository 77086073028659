import { forwardRef, useImperativeHandle, useRef } from 'react';
import FlashPopper from './FlashPopper';

const ClipboardCopy = forwardRef(
  (
    {
      flashPopperClassName,
      flashPopperPlacement,
      flashPopperMessage,
      flashPopperDelay,
    },
    ref
  ) => {
    const input = useRef();
    const flashPopper = useRef();

    useImperativeHandle(ref, () => ({
      copy(event, value) {
        /* Select the text field */
        input.current.value = value;
        input.current.style.display = 'inline';
        try {
          const doIt = async () => {
            let copied = false;
            input.current.focus();
            input.current.select();
            input.current.setSelectionRange(0, 99999); /* For mobile devices */

            try {
              await navigator.clipboard.writeText(input.current.value);
              copied = true;
            } catch {
              copied = document.execCommand('copy');
            }
            if (copied) {
              flashPopper.current.pop({
                domElement: event.target,
                className: 'copied',
                placement: flashPopperPlacement || 'right',
                message: flashPopperMessage || '¡copied!',
                delay: Number(flashPopperDelay) || 2000,
              });
            }
          };

          doIt().finally(() => {
            input.current.style.display = 'none';
          });
        } catch (e) {
          console.error(e);
        } finally {
        }
      },
    }));

    return (
      <>
        <FlashPopper className={flashPopperClassName} ref={flashPopper} />
        <textarea
          ref={input}
          onChange={() => {}}
          type="text"
          value=""
          id="clipboardInput"
          style={{ display: 'none', width: 0, height: 0 }}
        />
      </>
    );
  }
);

export default ClipboardCopy;
